import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import renderHTML from 'react-render-html'

const Content = ({ alignText, content, date, id, width }) => {
    return (
        <Grid
            alignItems='center'
            color='white.main'
            container="true"
            flexDirection='column'
            justifyContent='center'
            id={id}
            py={5}
            px={[4, 4, 4, 4, 4, 4, 0]}
            sx={{
                scrollMarginTop: '63px',
            }}>
            <Box width={width || ['auto', 'auto', 'auto', 'auto', 640]}>
                { date ? <Typography fontFamily='ManropeSemiBold' fontSize='14px'>Effective Date: {date}</Typography> : null }
                { content ?
                    <Typography
                        variant='content'
                        textAlign={alignText || 'left'}>
                        {renderHTML(content)}
                    </Typography>:
                    null
                }
            </Box>
        </Grid>
    )
}

export default Content
