import React from 'react'
import { Box, Grid, Typography } from '@mui/material'

const Heading = ({ align, as, content, width, variant }) => {
    return (
        <Grid alignItems='center' container="true" justifyContent='center' px={[2, 2, 2, 2, 2, 2, 0]}>
            <Box width={width || ['auto', 'auto', 'auto', 'auto', 640]}>
                <Typography textAlign={align || 'center'} variant={variant || as} component={as} color='white.main'>{content}</Typography>
            </Box>
        </Grid>
    )
}

export default Heading
